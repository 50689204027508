import React from 'react';
import AboutTreviseSection from './AboutTreviseSection';
import ExampleFeedbackSection from './ExampleFeedbackSection';
import GiveTreviseAShotSection from './GiveTreviseAShotSection';
import KeyFeaturesSection from './KeyFeaturesSection';
import StartYourJourneySecrion from './StartYourJourneySecrion';

export default function HomePage() {
  return (
    <>
      <AboutTreviseSection />
      <KeyFeaturesSection />
      <ExampleFeedbackSection />
      <GiveTreviseAShotSection />
      <StartYourJourneySecrion />
    </>
  );
}
