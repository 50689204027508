import React, { useState } from 'react';
import { Modal, Button, Row, Col } from 'antd';
import Icon from 'components/Icon';
import styles from './Pricing.module.less';
import JoinBetaModal from 'screens/JoinBetaModal';
import { EmailDto } from 'services/api';
type Props = {
  onClose: () => void;
};

export default function Pricing(props: Props) {
  const [isJoinBetaVisible, setIsJoinBetaVisible] = useState(false);
  const [subscription, setSubscription] =
    useState<EmailDto.subscriptionPlan | null>();

  const { onClose } = props;

  return (
    <>
      {isJoinBetaVisible && (
        <JoinBetaModal
          header="Subscription request"
          text="Please send us your request and our team will contact you back as soon as possible"
          buttonName="Send request"
          onClose={() => {
            setIsJoinBetaVisible(false);
            setSubscription(null);
          }}
          subscription={subscription}
        />
      )}
      <Modal
        title={null}
        open
        centered
        closable={false}
        maskClosable={false}
        onCancel={onClose}
        footer={null}
        destroyOnClose
        width="1321px"
      >
        <>
          <div className="modal-header">
            <h2 className={styles.title}>
              Introducing Trevise’s Scalable Pricing
            </h2>

            <button className="modal-close" onClick={onClose}>
              <Icon icon="cross" />
            </button>
          </div>

          <div className={'modal-scroll-mobile'}>
            <div className="modal-scroll-desc">
              <h4 className={styles.subtitle}>
                Pay only for the feedback analysis you use!
              </h4>
              <p className={styles.caption}>
                Trevise believes in fair and flexible pricing. With a scalable
                pricing model, you have full control over your expenses:
              </p>

              <Row gutter={[16, 8]}>
                <Col xs={24} md={12} lg={8} xl={6} className={styles.flex}>
                  <div className={styles.card}>
                    <div className={styles.cardText}>
                      <div>Trial</div>
                      <h2 className={styles.cardPrice}>Free!</h2>
                      <p className={styles.cardSeparator}>for</p>
                      <div>50 feedbacks</div>
                    </div>
                    <div className={styles.cardCaption}>
                      <Button
                        onClick={() => {
                          setIsJoinBetaVisible(true);
                          setSubscription(EmailDto.subscriptionPlan.TRIAL);
                        }}
                        size="large"
                        className="inverse-color"
                        block
                      >
                        Get started
                      </Button>
                      <p>Key features:</p>
                      <ul>
                        <li>categorized results</li>
                        <li>unique reply suggestions</li>
                        <li>alerts</li>
                        <li>service improvement suggestions</li>
                        <li>multilingual support</li>
                        <li>backend integration</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8} xl={6} className={styles.flex}>
                  <div className={styles.card}>
                    <div className={styles.cardText}>
                      <div>Starter</div>
                      <h2 className={styles.cardPrice}>$ 19</h2>
                      <p className={styles.cardSeparator}>for</p>
                      <div>100 feedbacks</div>
                    </div>
                    <div className={styles.cardCaption}>
                      <Button
                        onClick={() => {
                          setIsJoinBetaVisible(true);
                          setSubscription(EmailDto.subscriptionPlan.STARTER);
                        }}
                        size="large"
                        className="inverse-color"
                        block
                      >
                        Get started
                      </Button>
                      <p>Trial key features, plus:</p>
                      <ul>
                        <li>API integration</li>
                        <li>online support</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8} xl={6} className={styles.flex}>
                  <div className={styles.card}>
                    <div className={styles.cardText}>
                      <div>
                        Pro{' '}
                        <strong className={styles.cardTag}>Recommended</strong>
                      </div>
                      <h2 className={styles.cardPrice}>$ 99</h2>
                      <p className={styles.cardSeparator}>for</p>
                      <div>1000 feedbacks</div>
                    </div>
                    <div className={styles.cardCaption}>
                      <Button
                        onClick={() => {
                          setIsJoinBetaVisible(true);
                          setSubscription(EmailDto.subscriptionPlan.PRO);
                        }}
                        size="large"
                        className="inverse-color"
                        block
                      >
                        Get started
                      </Button>
                      <p>Starter key features, plus:</p>
                      <ul>
                        <li>custom integration</li>
                        <li>advanced analytics</li>
                        <li>premium support</li>
                      </ul>
                    </div>
                  </div>
                </Col>
                <Col xs={24} md={12} lg={8} xl={6} className={styles.flex}>
                  <div className={styles.card}>
                    <div className={styles.cardText}>
                      <div>Enterprise</div>
                      <h2 className={styles.cardPrice}>Contractual</h2>
                      <p className={styles.cardSeparator}>for</p>
                      <div>custom feedbacks</div>
                    </div>
                    <div className={styles.cardCaption}>
                      <Button
                        onClick={() => {
                          setIsJoinBetaVisible(true);
                          setSubscription(EmailDto.subscriptionPlan.ENTERPRISE);
                        }}
                        size="large"
                        className="inverse-color"
                        block
                      >
                        Contact us
                      </Button>
                      <p>
                        Have a specific task for Trevise? <br />
                        We’re flexible and love new challenges, so we’ll do our
                        best to work something out!
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </>
      </Modal>
    </>
  );
}
