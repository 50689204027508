import Layout from 'components/Layout';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga4';
import TagManager from 'react-gtm-module';
import { ConfigProvider } from 'antd';
import './styles/index.less';
import { AppThemeProvider } from 'configs/AppThemeProvider';
import { IntercomProvider } from 'react-use-intercom';
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_ID!);
TagManager.initialize({ gtmId: process.env.REACT_APP_GOOGLE_TAG_ID! });
function App() {
  return (
    <ConfigProvider theme={AppThemeProvider}>
      <IntercomProvider appId={process.env.REACT_APP_INTERCOM_ID!}>
        <BrowserRouter>
          <Layout />
        </BrowserRouter>
      </IntercomProvider>
    </ConfigProvider>
  );
}

export default App;
