/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { EmailDto } from '../models/EmailDto';
import type { LongCommentDto } from '../models/LongCommentDto';
import type { ShortCommentDto } from '../models/ShortCommentDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';

export class DefaultService {

    constructor(public readonly httpRequest: BaseHttpRequest) {}

    /**
     * @param model
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public appControllerParseCommentStream(
        model: 'gpt-3.5-turbo' | 'gpt-4',
        requestBody: LongCommentDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/comments/parse-comment/stream',
            query: {
                'model': model,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @returns ShortCommentDto
     * @throws ApiError
     */
    public appControllerGetComments(): CancelablePromise<Array<ShortCommentDto>> {
        return this.httpRequest.request({
            method: 'GET',
            url: '/api/comments/list',
        });
    }

    /**
     * @param requestBody
     * @returns any
     * @throws ApiError
     */
    public appControllerSaveUserEmail(
        requestBody: EmailDto,
    ): CancelablePromise<any> {
        return this.httpRequest.request({
            method: 'POST',
            url: '/api/comments/email',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

}
