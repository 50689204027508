import React, { useState } from 'react';

import { Tabs, Carousel, Select } from 'antd';
import Icon from 'components/Icon';
import styles from './ExampleFeedbackSection.module.less';
import cx from 'classnames';
import { useMediaMatch } from 'rooks';
import { MobileScreenQuery } from 'utils/mediaQuery.utils';

import exampleEcommerceDesktop1 from 'assets/images/examplePhotos/desktop/E-commerce1d.jpg';
import exampleEcommerceMobile1 from 'assets/images/examplePhotos/mobile/E-commerce1m.jpg';
import exampleEcommerceDesktop2 from 'assets/images/examplePhotos/desktop/E-commerce2d.jpg';
import exampleEcommerceMobile2 from 'assets/images/examplePhotos/mobile/E-commerce2m.jpg';
import exampleEcommerceDesktop3 from 'assets/images/examplePhotos/desktop/E-commerce3d.jpg';
import exampleEcommerceMobile3 from 'assets/images/examplePhotos/mobile/E-commerce3m.jpg';
import exampleEcommerceDesktop4 from 'assets/images/examplePhotos/desktop/E-commerce4d.jpg';
import exampleEcommerceMobile4 from 'assets/images/examplePhotos/mobile/E-commerce4m.jpg';

import exampleRetailDesktop1 from 'assets/images/examplePhotos/desktop/Retail1d.jpg';
import exampleRetailMobile1 from 'assets/images/examplePhotos/mobile/Retail1m.jpg';
import exampleRetailDesktop2 from 'assets/images/examplePhotos/desktop/Retail2d.jpg';
import exampleRetailMobile2 from 'assets/images/examplePhotos/mobile/Retail2m.jpg';
import exampleRetailDesktop3 from 'assets/images/examplePhotos/desktop/Retail3d.jpg';
import exampleRetailMobile3 from 'assets/images/examplePhotos/mobile/Retail3m.jpg';
import exampleRetailDesktop4 from 'assets/images/examplePhotos/desktop/Retail4d.jpg';
import exampleRetailMobile4 from 'assets/images/examplePhotos/mobile/Retail4m.jpg';

import exampleHospitalityDesktop1 from 'assets/images/examplePhotos/desktop/Hospitality1d.jpg';
import exampleHospitalityMobile1 from 'assets/images/examplePhotos/mobile/Hospitality1m.jpg';
import exampleHospitalityDesktop2 from 'assets/images/examplePhotos/desktop/Hospitality2d.jpg';
import exampleHospitalityMobile2 from 'assets/images/examplePhotos/mobile/Hospitality2m.jpg';
import exampleHospitalityDesktop3 from 'assets/images/examplePhotos/desktop/Hospitality3d.jpg';
import exampleHospitalityMobile3 from 'assets/images/examplePhotos/mobile/Hospitality3m.jpg';
import exampleHospitalityDesktop4 from 'assets/images/examplePhotos/desktop/Hospitality4d.jpg';
import exampleHospitalityMobile4 from 'assets/images/examplePhotos/mobile/Hospitality4m.jpg';

import exampleTourismDesktop1 from 'assets/images/examplePhotos/desktop/Tourism1d.jpg';
import exampleTourismMobile1 from 'assets/images/examplePhotos/mobile/Tourism1m.jpg';
import exampleTourismDesktop2 from 'assets/images/examplePhotos/desktop/Tourism2d.jpg';
import exampleTourismMobile2 from 'assets/images/examplePhotos/mobile/Tourism2m.jpg';
import exampleTourismDesktop3 from 'assets/images/examplePhotos/desktop/Tourism3d.jpg';
import exampleTourismMobile3 from 'assets/images/examplePhotos/mobile/Tourism3m.jpg';
import exampleTourismDesktop4 from 'assets/images/examplePhotos/desktop/Tourism4d.jpg';
import exampleTourismMobile4 from 'assets/images/examplePhotos/mobile/Tourism4m.jpg';

import exampleHealthcareDesktop1 from 'assets/images/examplePhotos/desktop/Healthcare1d.jpg';
import exampleHealthcareMobile1 from 'assets/images/examplePhotos/mobile/Healthcare1m.jpg';
import exampleHealthcareDesktop2 from 'assets/images/examplePhotos/desktop/Healthcare2d.jpg';
import exampleHealthcareMobile2 from 'assets/images/examplePhotos/mobile/Healthcare2m.jpg';
import exampleHealthcareDesktop3 from 'assets/images/examplePhotos/desktop/Healthcare3d.jpg';
import exampleHealthcareMobile3 from 'assets/images/examplePhotos/mobile/Healthcare3m.jpg';
import exampleHealthcareDesktop4 from 'assets/images/examplePhotos/desktop/Healthcare4d.jpg';
import exampleHealthcareMobile4 from 'assets/images/examplePhotos/mobile/Healthcare4m.jpg';

import exampleTelecommunicationsDesktop1 from 'assets/images/examplePhotos/desktop/Telecommunications1d.jpg';
import exampleTelecommunicationsMobile1 from 'assets/images/examplePhotos/mobile/Telecommunications1m.jpg';
import exampleTelecommunicationsDesktop2 from 'assets/images/examplePhotos/desktop/Telecommunications2d.jpg';
import exampleTelecommunicationsMobile2 from 'assets/images/examplePhotos/mobile/Telecommunications2m.jpg';
import exampleTelecommunicationsDesktop3 from 'assets/images/examplePhotos/desktop/Telecommunications3d.jpg';
import exampleTelecommunicationsMobile3 from 'assets/images/examplePhotos/mobile/Telecommunications3m.jpg';
import exampleTelecommunicationsDesktop4 from 'assets/images/examplePhotos/desktop/Telecommunications4d.jpg';
import exampleTelecommunicationsMobile4 from 'assets/images/examplePhotos/mobile/Telecommunications4m.jpg';

import exampleITServicesDesktop1 from 'assets/images/examplePhotos/desktop/ITServices1d.jpg';
import exampleITServicesMobile1 from 'assets/images/examplePhotos/mobile/ITServices1m.jpg';
import exampleITServicesDesktop2 from 'assets/images/examplePhotos/desktop/ITServices2d.jpg';
import exampleITServicesMobile2 from 'assets/images/examplePhotos/mobile/ITServices2m.jpg';
import exampleITServicesDesktop3 from 'assets/images/examplePhotos/desktop/ITServices3d.jpg';
import exampleITServicesMobile3 from 'assets/images/examplePhotos/mobile/ITServices3m.jpg';
import exampleITServicesDesktop4 from 'assets/images/examplePhotos/desktop/ITServices4d.jpg';
import exampleITServicesMobile4 from 'assets/images/examplePhotos/mobile/ITServices4m.jpg';

import exampleInsuranceDesktop1 from 'assets/images/examplePhotos/desktop/Insurance1d.jpg';
import exampleInsuranceMobile1 from 'assets/images/examplePhotos/mobile/Insurance1m.jpg';
import exampleInsuranceDesktop2 from 'assets/images/examplePhotos/desktop/Insurance2d.jpg';
import exampleInsuranceMobile2 from 'assets/images/examplePhotos/mobile/Insurance2m.jpg';
import exampleInsuranceDesktop3 from 'assets/images/examplePhotos/desktop/Insurance3d.jpg';
import exampleInsuranceMobile3 from 'assets/images/examplePhotos/mobile/Insurance3m.jpg';
import exampleInsuranceDesktop4 from 'assets/images/examplePhotos/desktop/Insurance4d.jpg';
import exampleInsuranceMobile4 from 'assets/images/examplePhotos/mobile/Insurance4m.jpg';

import exampleConsumerGoodsDesktop1 from 'assets/images/examplePhotos/desktop/ConsumerGoods1d.jpg';
import exampleConsumerGoodsMobile1 from 'assets/images/examplePhotos/mobile/ConsumerGoods1m.jpg';
import exampleConsumerGoodsDesktop2 from 'assets/images/examplePhotos/desktop/ConsumerGoods2d.jpg';
import exampleConsumerGoodsMobile2 from 'assets/images/examplePhotos/mobile/ConsumerGoods2m.jpg';
import exampleConsumerGoodsDesktop3 from 'assets/images/examplePhotos/desktop/ConsumerGoods3d.jpg';
import exampleConsumerGoodsMobile3 from 'assets/images/examplePhotos/mobile/ConsumerGoods3m.jpg';
import exampleConsumerGoodsDesktop4 from 'assets/images/examplePhotos/desktop/ConsumerGoods4d.jpg';
import exampleConsumerGoodsMobile4 from 'assets/images/examplePhotos/mobile/ConsumerGoods4m.jpg';

import exampleAutomotiveIndustryDesktop1 from 'assets/images/examplePhotos/desktop/AutomotiveIndustry1d.jpg';
import exampleAutomotiveIndustryMobile1 from 'assets/images/examplePhotos/mobile/AutomotiveIndustry1m.jpg';
import exampleAutomotiveIndustryDesktop2 from 'assets/images/examplePhotos/desktop/AutomotiveIndustry2d.jpg';
import exampleAutomotiveIndustryMobile2 from 'assets/images/examplePhotos/mobile/AutomotiveIndustry2m.jpg';
import exampleAutomotiveIndustryDesktop3 from 'assets/images/examplePhotos/desktop/AutomotiveIndustry3d.jpg';
import exampleAutomotiveIndustryMobile3 from 'assets/images/examplePhotos/mobile/AutomotiveIndustry3m.jpg';
import exampleAutomotiveIndustryDesktop4 from 'assets/images/examplePhotos/desktop/AutomotiveIndustry4d.jpg';
import exampleAutomotiveIndustryMobile4 from 'assets/images/examplePhotos/mobile/AutomotiveIndustry4m.jpg';

export default function ExampleFeedbackSection() {
  const isMobileScreen = useMediaMatch(MobileScreenQuery);

  const arrayTabs = [
    {
      name: 'E-commerce',
      icon: 'shopping-cart',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleEcommerceMobile1
            : exampleEcommerceDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleEcommerceMobile2
            : exampleEcommerceDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleEcommerceMobile3
            : exampleEcommerceDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleEcommerceMobile4
            : exampleEcommerceDesktop4,
        },
      ],
    },
    {
      name: 'Retail',
      icon: 'store',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleRetailMobile1
            : exampleRetailDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleRetailMobile2
            : exampleRetailDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleRetailMobile3
            : exampleRetailDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleRetailMobile4
            : exampleRetailDesktop4,
        },
      ],
    },
    {
      name: 'Hospitality',
      icon: 'utensils',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleHospitalityMobile1
            : exampleHospitalityDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleHospitalityMobile2
            : exampleHospitalityDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleHospitalityMobile3
            : exampleHospitalityDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleHospitalityMobile4
            : exampleHospitalityDesktop4,
        },
      ],
    },
    {
      name: 'Tourism',
      icon: 'metro',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleTourismMobile1
            : exampleTourismDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleTourismMobile2
            : exampleTourismDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleTourismMobile3
            : exampleTourismDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleTourismMobile4
            : exampleTourismDesktop4,
        },
      ],
    },
    {
      name: 'Healthcare',
      icon: 'medkit',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleHealthcareMobile1
            : exampleHealthcareDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleHealthcareMobile2
            : exampleHealthcareDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleHealthcareMobile3
            : exampleHealthcareDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleHealthcareMobile4
            : exampleHealthcareDesktop4,
        },
      ],
    },
    {
      name: 'Telecommunications',
      icon: 'tv-retro',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleTelecommunicationsMobile1
            : exampleTelecommunicationsDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleTelecommunicationsMobile2
            : exampleTelecommunicationsDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleTelecommunicationsMobile3
            : exampleTelecommunicationsDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleTelecommunicationsMobile4
            : exampleTelecommunicationsDesktop4,
        },
      ],
    },
    {
      name: 'IT services',
      icon: 'code',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleITServicesMobile1
            : exampleITServicesDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleITServicesMobile2
            : exampleITServicesDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleITServicesMobile3
            : exampleITServicesDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleITServicesMobile4
            : exampleITServicesDesktop4,
        },
      ],
    },
    {
      name: 'Insurance',
      icon: 'shield-check',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleInsuranceMobile1
            : exampleInsuranceDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleInsuranceMobile2
            : exampleInsuranceDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleInsuranceMobile3
            : exampleInsuranceDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleInsuranceMobile4
            : exampleInsuranceDesktop4,
        },
      ],
    },
    {
      name: 'Consumer goods',
      icon: 'shopping-bag',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleConsumerGoodsMobile1
            : exampleConsumerGoodsDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleConsumerGoodsMobile2
            : exampleConsumerGoodsDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleConsumerGoodsMobile3
            : exampleConsumerGoodsDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleConsumerGoodsMobile4
            : exampleConsumerGoodsDesktop4,
        },
      ],
    },
    {
      name: 'Automotive industry',
      icon: 'car-sideview',
      contentImages: [
        {
          imageUrl: isMobileScreen
            ? exampleAutomotiveIndustryMobile1
            : exampleAutomotiveIndustryDesktop1,
        },
        {
          imageUrl: isMobileScreen
            ? exampleAutomotiveIndustryMobile2
            : exampleAutomotiveIndustryDesktop2,
        },
        {
          imageUrl: isMobileScreen
            ? exampleAutomotiveIndustryMobile3
            : exampleAutomotiveIndustryDesktop3,
        },
        {
          imageUrl: isMobileScreen
            ? exampleAutomotiveIndustryMobile4
            : exampleAutomotiveIndustryDesktop4,
        },
      ],
    },
  ];
  const [selectedOption, setSelectedOption] = useState(arrayTabs[0].name);
  const handleSelectChange = (value: string) => {
    setSelectedOption(value);
  };
  if (isMobileScreen) {
    return (
      <section className={styles.section}>
        <div className="container">
          <div className={styles.titleWrap}>
            <h2 className="h2">
              See how Trevise can analyse your customer feedback
            </h2>
            <p className={styles.subtitleWrap}>
              We've prepared a sentiment analysis example for every industry we
              worked with, so that you can take a look at Trevise's features in
              action
            </p>
          </div>
          <Select
            defaultValue={arrayTabs[0].name}
            onChange={handleSelectChange}
            className={styles.select}
            popupClassName={styles.selectPop}
            virtual={false}
            suffixIcon={<Icon icon="chevron-down" />}
          >
            {arrayTabs.map((tab) => (
              <Select.Option key={tab.name} value={tab.name}>
                <span className={styles.tabIcon}>
                  <Icon icon={tab.icon} />
                </span>{' '}
                {tab.name}
              </Select.Option>
            ))}
          </Select>
          {arrayTabs.map(
            (tab, index) =>
              selectedOption === tab.name && (
                <div key={index}>
                  {tab.contentImages.length > 1 ? (
                    <Carousel arrows={false}>
                      {tab.contentImages.map((image, imgIndex) => (
                        <div key={imgIndex}>
                          <img
                            src={image.imageUrl}
                            alt={`${tab.name} ${imgIndex + 1}`}
                          />
                        </div>
                      ))}
                    </Carousel>
                  ) : (
                    <img
                      src={tab.contentImages[0].imageUrl}
                      alt={`${tab.name} 1`}
                    />
                  )}
                </div>
              ),
          )}
        </div>
      </section>
    );
  } else {
    return (
      <section className={styles.section}>
        <div className="container">
          <div className={styles.titleWrap}>
            <h2 className="h3">
              See how Trevise can analyse your customer feedback
            </h2>

            <p>
              We've prepared a sentiment analysis example for every industry we
              worked with, so that you can take a look at Trevise's features in
              action
            </p>
          </div>

          <Tabs className={styles.tabs} tabPosition="left">
            {arrayTabs.map((tab, index) => (
              <Tabs.TabPane
                key={index}
                tab={
                  <>
                    <span className={styles.tabIcon}>
                      <Icon icon={tab.icon} />
                    </span>{' '}
                    {tab.name}
                  </>
                }
              >
                {tab.contentImages.length > 1 ? (
                  <div className={styles.slider}>
                    <Carousel
                      arrows={true}
                      prevArrow={
                        <button>
                          <Icon icon="arrow-left" />
                        </button>
                      }
                      nextArrow={
                        <button>
                          <Icon icon="arrow-right" />
                        </button>
                      }
                    >
                      {tab.contentImages.map((image, imgIndex) => (
                        <div key={imgIndex}>
                          <img
                            src={image.imageUrl}
                            alt={`${tab.name} ${imgIndex + 1}`}
                          />
                        </div>
                      ))}
                    </Carousel>
                  </div>
                ) : (
                  <img
                    src={tab.contentImages[0].imageUrl}
                    alt={`${tab.name} 1`}
                  />
                )}
              </Tabs.TabPane>
            ))}
          </Tabs>
        </div>
      </section>
    );
  }
}
