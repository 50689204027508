import React, { useState, useMemo, useEffect } from 'react';
import { Button, Form, Input, message } from 'antd';
import FormItem from 'components/form/FormItem';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useIntercom } from 'react-use-intercom';
import * as yup from 'yup';
import arrowHitMobile from 'assets/images/ArrowHitMobile.svg';
import ArrowTryOurText from 'assets/images/ArrowTryOurText.svg';

import arrowHitDesktop from 'assets/images/ArrowHitDesktop.svg';
import arrowExampleMobile from 'assets/images/ArrowExampleMobile.svg';
import arrowExampleDesktop from 'assets/images/ArrowExampleDesktop.svg';
import JoinBetaModal from 'screens/JoinBetaModal';
import { useMediaMatch } from 'rooks';
import AnalizedCommentModal from 'screens/AnalizedCommentModal';
import { MobileScreenQuery } from 'utils/mediaQuery.utils';
import PrivacyPolicy from 'screens/PrivacyPolicy';
import styles from './GiveTreviseAShotSection.module.less';
import CommonApi from 'services/api/common.api';
import { ShortCommentDto } from 'services/api';
import { onlySpacesHyphensRegExp } from 'utils/validations.utils';

interface IFormValues {
  comment: string;
}

interface ICategories {
  is_positive_alert: boolean | null;
  alert_reason: string;
  suggestions: string;
  auto_response: string;
  categories: Array<any>;
}

const getValidationSchema = () =>
  yup.object().shape({
    comment: yup
      .string()
      .required('Oops! It seems you forgot to enter the text for analysis')
      .test(
        'commentTest',
        'Uh-oh! I need some actual content, not just spaces',
        (val) => {
          return !val?.match(onlySpacesHyphensRegExp);
        },
      )
      .max(3000, 'Oops! You are out of the limit of 3000 characters'),
  });
export default function GiveTreviseAShotSection() {
  const { trackEvent, boot } = useIntercom();
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  const handleTrackEvent = () => {
    const id = setTimeout(() => {
      boot({ email: localStorage.getItem('email') || '' });
      trackEvent('attempts-restriction-error');
      setTimeoutId(id);
    }, 3 * 60 * 1000);
  };
  const initialCategoriesValue = {
    is_positive_alert: null,
    alert_reason: '',
    suggestions: '',
    auto_response: '',
    categories: [],
  };

  const isMobileScreen = useMediaMatch(MobileScreenQuery);
  const [isBetaFormSubscriptionsOpen, setIsBetaFormSubscriptionsOpen] =
    useState<boolean>(false);
  const [isAnalizedCommentOpen, setIsAnalizedCommentOpen] =
    useState<boolean>(false);
  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] =
    useState<boolean>(false);
  const [commentValue, setCommentValue] = useState<string>('');
  const [comments, setComments] = useState<Array<ShortCommentDto>>([]);
  const [analizedCategoriesFull, setAnalizedCategoriesFull] =
    useState<ICategories>(initialCategoriesValue);
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const handleUseExample = () => {
    if (comments.length !== 0) {
      const nextIndex = (currentIndex + 1) % comments.length;
      setFormValue('comment', comments[nextIndex].comment);
      trigger('comment');
      setCurrentIndex(nextIndex);
    }
  };
  let categorizedComments = false;
  const {
    control,
    handleSubmit,
    setValue: setFormValue,
    trigger,
  } = useForm<IFormValues>({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  });
  const model = 'gpt-4';

  useEffect(() => {
    const loadComment = async () => {
      try {
        const result = await CommonApi.getComments();
        setComments(result);
      } catch (error: any) {
        setComments([]);
        if (error.status === 429 || error.code === 'ERR_NETWORK') {
          message.error(
            'Oops! Too many requests. Please wait for a few seconds.',
            5,
          );
        } else {
          message.error(error.message, 5);
        }
      }
    };

    loadComment();
  }, []);
  useEffect(() => {
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  const onSubmit = (values: IFormValues) => {
    setCommentValue(values.comment);
    const storedValue = localStorage.getItem('IsSubscribed');
    const isSubscribed = storedValue === 'true';
    const isCommentDifferent = comments.every(
      (commentObj) => commentObj.comment !== values.comment,
    );
    const count = localStorage.getItem('count');
    if (Number(count) >= 10 && isCommentDifferent) {
      handleTrackEvent();
      message.error(
        'Oops! Looks like you`ve hit the limit. Don`t worry though, just reach out to us for some extra analysis',
        5,
      );
      return;
    }

    if (isSubscribed && isCommentDifferent) {
      localStorage.setItem(
        'count',
        localStorage.getItem('count') === undefined
          ? '1'
          : String(Number(localStorage.getItem('count')) + Number('1')),
      );
      const id = setTimeout(() => {
        boot({ email: localStorage.getItem('email') || '' });
        trackEvent('unleash-the-analysis-attempts');
        setTimeoutId(id);
      }, 3 * 60 * 1000);
    }
    if (isSubscribed || !isCommentDifferent) {
      setIsAnalizedCommentOpen(true);
      const urlPostCategories = `${process.env.REACT_APP_API_BASE_URL}/api/comments/parse-comment/stream?model=${model}`;
      const fetchData = async () => {
        setIsLoading(true);
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            comment: values.comment,
          }),
        };
        try {
          const response = await fetch(urlPostCategories, requestOptions);
          if (!response.ok) {
            if (response.status === 409) {
              message.error(
                'Oops! It seems that the email has already been submitted. If you need further analysis, please don`t hesitate to contact our team',
                5,
              );
            } else if (response.status === 429) {
              message.error('Rate limit is exceeded. Try again later.', 5);
            } else {
              message.error(
                'Apologies! The Result page is currently unavailable. Please try again',
                5,
              );
            }

            return;
          }
          const reader = response.body!.getReader();
          let result = '';
          let resultFull = '';
          let stringifyResult = '';
          const processStream = async () => {
            try {
              while (true) {
                const { done, value } = await reader.read();
                if (done) {
                  break;
                }
                const chunk = new TextDecoder('utf-8').decode(value);
                resultFull = resultFull + chunk;
                if (chunk.includes('[')) {
                  categorizedComments = true;
                }
                if (categorizedComments) {
                  if (chunk.includes(']')) {
                    break;
                  }
                  result = result + chunk;
                  const regexObject = /\{[^{}]*\}/g;
                  let matchCategory;
                  while ((matchCategory = regexObject.exec(result)) !== null) {
                    const category = matchCategory[0];
                    const parseJSON = JSON.parse(category);
                    setAnalizedCategoriesFull((prev: any) => ({
                      ...prev,
                      categories: prev.categories.concat(parseJSON),
                    }));
                    result = '';
                  }
                }
                stringifyResult = stringifyResult.concat(chunk);
                const regexAlertIsPositive =
                  /"is_positive_alert":\s*([^,]+)\s*,/;
                const regexAlertReason = /"alert_reason": "([^"]+)"/;
                const regexAutoResponse = /"auto_response": "([^"]+)"/;
                const regexSuggestions = /"suggestions": "([^"]+)"/;
                const matchAlertIsPositive =
                  stringifyResult.match(regexAlertIsPositive);
                const matchAlertReason =
                  stringifyResult.match(regexAlertReason);
                const matchAutoResponse =
                  stringifyResult.match(regexAutoResponse);
                const matchSuggestions =
                  stringifyResult.match(regexSuggestions);
                if (matchAlertIsPositive && matchAlertIsPositive[1]) {
                  const alertIsPositive = matchAlertIsPositive[1];
                  setAnalizedCategoriesFull((prev: any) => ({
                    ...prev,
                    is_positive_alert: /^true$/i.test(alertIsPositive),
                  }));
                  stringifyResult = '';
                }
                if (matchAlertReason && matchAlertReason[1]) {
                  const alertReason = matchAlertReason[1];
                  setAnalizedCategoriesFull((prev: any) => ({
                    ...prev,
                    alert_reason: alertReason,
                  }));
                  stringifyResult = '';
                }
                if (matchAutoResponse && matchAutoResponse[1]) {
                  const autoResponse = matchAutoResponse[1];
                  setAnalizedCategoriesFull((prev: any) => ({
                    ...prev,
                    auto_response: autoResponse,
                  }));
                  stringifyResult = '';
                }
                if (matchSuggestions && matchSuggestions[1]) {
                  const suggestions = matchSuggestions[1];
                  setAnalizedCategoriesFull((prev: any) => ({
                    ...prev,
                    suggestions: suggestions,
                  }));
                  stringifyResult = '';
                }
              }
            } catch (error: any) {
              if (error.status === 503 || error.status === 504) {
                message.error(
                  'Apologies! The Result page is currently unavailable. Please try again',
                  5,
                );
              } else {
                if (error.body) {
                  message.error(error.body.message, 5);
                } else {
                  message.error(error.message, 5);
                }
              }
            }
            return resultFull;
          };
          const responseData = await processStream();
          const updatedFullResponse = `${responseData}`;

          if (
            updatedFullResponse.charAt(updatedFullResponse.length - 1) === '}'
          ) {
            try {
              const fullParsedJson = JSON.parse(updatedFullResponse);
              setAnalizedCategoriesFull(fullParsedJson);
            } catch (error: any) {
              console.log(error);
            }
          } else {
            try {
              const fullParsedJson = JSON.parse(`${updatedFullResponse}}`);
              setAnalizedCategoriesFull(fullParsedJson);
            } catch (error: any) {
              console.log(error);
            }
          }
        } catch (error: any) {
          if (error.status === 503 || error.status === 504) {
            message.error(
              'Apologies! The Result page is currently unavailable. Please try again',
              5,
            );
          } else {
            if (error.body) {
              message.error(error.body.message, 5);
            } else {
              message.error(error.message, 5);
            }
          }
        } finally {
          setIsLoading(false);
        }
      };
      fetchData();
    } else {
      setIsBetaFormSubscriptionsOpen(true);
    }
  };

  return (
    <>
      {isPrivacyPolicyOpen && (
        <PrivacyPolicy
          onClose={() => {
            setIsPrivacyPolicyOpen(false);
          }}
        />
      )}
      {isBetaFormSubscriptionsOpen && (
        <JoinBetaModal
          onClose={() => {
            setIsBetaFormSubscriptionsOpen(false);
          }}
          onSuccess={() => {
            setIsBetaFormSubscriptionsOpen(false);
            setIsAnalizedCommentOpen(true);
            onSubmit({ comment: commentValue });
          }}
          header="Your report is almost ready!"
          text="Unlock 10 free requests on Trevise. Also, receive all the latest updates and news on building the tool of the dream"
          buttonName="Analyse feedback"
          isUnleash={true}
        />
      )}
      {isAnalizedCommentOpen && (
        <AnalizedCommentModal
          comment={commentValue}
          data={analizedCategoriesFull}
          isLoading={isLoading}
          onClose={() => {
            setAnalizedCategoriesFull(initialCategoriesValue);
            setIsAnalizedCommentOpen(false);
          }}
          onTryAgain={() => {
            setFormValue('comment', '');
            setAnalizedCategoriesFull(initialCategoriesValue);
            setCommentValue('');
            setIsAnalizedCommentOpen(false);
          }}
        />
      )}
      <section className={styles.section}>
        <div className="container">
          <div className={styles.wrap}>
            <div>
              <h2 className="h2">Finally, try Trevise yourself!</h2>

              <p className={styles.text}>
                Here, you can give our platform a shot by analyzing your own
                customer feedback. Just paste the text, sit back, and let
                Trevise handle the rest.
              </p>

              <div className={styles.topBar}>
                {}
                <div>
                  <h3 className={styles.title}>Try yourself</h3>

                  <div className={styles.topHint}>
                    <span>paste your text here</span>
                    <img
                      src={
                        isMobileScreen
                          ? arrowExampleMobile
                          : arrowExampleDesktop
                      }
                      alt="arrow to textarea"
                    />
                  </div>
                </div>
                <div className={styles.topBtn}>
                  {!isMobileScreen ? (
                    <div className={styles.btnHint}>
                      <img src={ArrowTryOurText} alt="arrow to textarea" />
                      <span> or try with our text</span>
                    </div>
                  ) : null}
                  <Button
                    onClick={handleUseExample}
                    className="inverse-color"
                    type="link"
                    size="small"
                  >
                    Use example
                  </Button>
                </div>
              </div>
            </div>

            <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
              <FormItem
                control={control}
                name="comment"
                render={({ field }) => (
                  <Input.TextArea
                    style={{ height: '188px' }}
                    placeholder="Provide feedback (up to 3000 characters)"
                    size="large"
                    {...field}
                  />
                )}
              />
              <div className={styles.botBar}>
                <div className={styles.btn}>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="large"
                    block
                    className="inverse-color"
                  >
                    Analyse feedback
                  </Button>
                </div>
                <div className={styles.botHint}>
                  <img
                    src={isMobileScreen ? arrowHitMobile : arrowHitDesktop}
                    alt="logo"
                    width="40px"
                    height="30px"
                  />
                  <span>hit the button to see the results</span>
                </div>
              </div>
            </Form>

            <div className={styles.privacyBox}>
              <span>
                For information about how Trevise processes your data, please
                review our &nbsp;
              </span>
              <Button
                type="link"
                className="inverse-color"
                onClick={() => {
                  setIsPrivacyPolicyOpen(true);
                }}
              >
                Privacy policy
              </Button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
