import { useState } from 'react';

import img from 'assets/images/food.svg';
import { Button } from 'antd';
import { useMediaMatch } from 'rooks';
import { MobileScreenQuery } from 'utils/mediaQuery.utils';
import styles from './ConsentPopUp.module.less';
import PrivacyPolicy from 'screens/PrivacyPolicy';

const storageType = localStorage;
const consentPropertyName = 'accepted_cookie';

export default function ConsentPopUp() {
  const isMobileScreen = useMediaMatch(MobileScreenQuery);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);

  const [accepted, setAccepted] = useState(
    !!storageType.getItem(consentPropertyName),
  );
  if (accepted) return null;
  return (
    <>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          onClose={() => {
            setShowPrivacyPolicy(false);
          }}
        />
      )}
      <div className={styles.wrapper}>
        <div className={styles.img}>
          <img src={img} alt="main" />
        </div>
        <div className={styles.text}>
          {!isMobileScreen && <h4 className="h4">Have cookies?</h4>}
          Trevise uses cookies to make your experience on the website better
        </div>
        <div className={styles.btn}>
          <Button
            size="large"
            onClick={() => {
              storageType.setItem(consentPropertyName, JSON.parse('true'));
              setAccepted(true);
            }}
            className="inverse-color"
          >
            Accept cookies
          </Button>

          <Button
            onClick={() => {
              setShowPrivacyPolicy(true);
            }}
            type="link"
            size="large"
            className="inverse-color"
          >
            Read more
          </Button>
        </div>
      </div>
    </>
  );
}
