import React, { useMemo, useState, useEffect } from 'react';
import { Modal } from 'antd';
import { Button, Form, Input, message } from 'antd';
import Icon from 'components/Icon';
import FormItem from 'components/form/FormItem';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import cx from 'classnames';
import styles from './JoinBetaModal.module.less';
import * as yup from 'yup';
import { CommonApi, EmailDto } from 'services/api';
import { emailRegex, onlySpacesHyphensRegExp } from 'utils/validations.utils';
import ReactGA from 'react-ga4';
import { useIntercom } from 'react-use-intercom';

type Props = {
  onClose: () => void;
  onSuccess?: () => void;
  header: string;
  text: string;
  buttonName: string;
  subscription?: EmailDto.subscriptionPlan | null;
  isUnleash?: boolean;
};
const getValidationSchema = () =>
  yup.object().shape({
    email: yup
      .string()
      .matches(emailRegex, 'Please enter a valid email address')
      .max(120, 'Oops! You are out of the limit of 120 characters')
      .required('Oops! It seems you forgot to enter your email'),
    companyName: yup
      .string()
      .required('Uh-oh! Looks like you missed a required field')
      .test(
        'companyName',
        'Whoops! We’re looking for an actual company name',
        (val) => {
          return !val?.match(onlySpacesHyphensRegExp);
        },
      )
      .max(40, 'Oops! You are out of the limit of 40 characters'),
  });

export default function JoinBetaModal(props: Props) {
  const validationSchema = useMemo(() => getValidationSchema(), []);
  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm<any>({
    resolver: yupResolver(validationSchema),
    mode: 'onTouched',
  });
  const { trackEvent, boot } = useIntercom();
  const {
    onClose,
    onSuccess,
    buttonName,
    header,
    text,
    subscription,
    isUnleash = false,
  } = props;
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);
  const onSubmit = async (values: any) => {
    localStorage.setItem('email', values.email);
    try {
      if (subscription) {
        await CommonApi.saveEmail({
          email: values.email,
          subscriptionPlan: subscription,
          company: values.companyName,
        });
        ReactGA.event({
          category: 'Button',
          action: `subscription_${subscription}_form_submission`,
          label: `Subscription ${subscription} form submission`,
        });
      } else {
        await CommonApi.saveEmail({
          email: values.email,
          company: values.companyName,
        });
        if (isUnleash) {
          const id = setTimeout(() => {
            boot({ email: localStorage.getItem('email') || '' });
            trackEvent('unleash-the-analysis-result');
            setTimeoutId(id);
          }, 3 * 60 * 1000);
          ReactGA.event({
            category: 'Button',
            action: 'submission_for_Unleash_the_analysis',
            label: 'Submission for Unleash the analysis',
          });
        } else {
          const id = setTimeout(() => {
            boot({ email: localStorage.getItem('email') || '' });
            trackEvent('join-beta');
            setTimeoutId(id);
          }, 3 * 60 * 1000);

          ReactGA.event({
            category: 'Button',
            action: `submission_for_Join_beta`,
            label: `submission for Join beta`,
          });
        }
      }
      localStorage.setItem('IsSubscribed', String(true));
      onSuccess && onSuccess();
      onClose();
    } catch (error: any) {
      if (error.status === 409) {
        message.error(
          'Oops! It seems that the email has already been submitted. If you need further analysis, please don’t hesitate to contact our team',
          5,
        );
      } else if (error.status === 503 || error.status === 504) {
        message.error(
          'Apologies! The Result page is currently unavailable. Please try again',
          5,
        );
      } else {
        if (error.body) {
          message.error(error.body.message, 5);
        } else {
          message.error(error.message, 5);
        }
      }
    }
  };
  return (
    <Modal
      title={null}
      open
      centered
      closable={false}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width="531px"
    >
      <>
        <div className="modal-header">
          <h2 className={cx('h4', styles.modalTitle)}>{header}</h2>
          <button className="modal-close" onClick={onClose}>
            <Icon icon="cross" />
          </button>
        </div>
        <div className="modal-scroll-mobile">
          <div className="modal-scroll-desc">
            <p className={styles.text}>{text}</p>
            <Form onFinish={handleSubmit(onSubmit)} layout="vertical">
              <FormItem
                name="companyName"
                control={control}
                render={({ field }) => (
                  <Input
                    placeholder="Your company name"
                    size="large"
                    {...field}
                  />
                )}
              />
              <FormItem
                name="email"
                control={control}
                render={({ field }) => (
                  <Input placeholder="Business email" size="large" {...field} />
                )}
              />

              <Button
                className="inverse-color"
                loading={isSubmitting}
                disabled={isSubmitting}
                type="primary"
                htmlType="submit"
                size="large"
                block
              >
                {buttonName}
              </Button>
            </Form>
          </div>
        </div>
      </>
    </Modal>
  );
}
