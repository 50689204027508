import React, { useState, useEffect } from 'react';
import { Modal, Collapse, Button } from 'antd';
import Icon from 'components/Icon';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useMediaMatch } from 'rooks';
import { MobileScreenQuery } from 'utils/mediaQuery.utils';
import treviseSpinner from 'assets/images/spiner_light.gif';
import Spin from 'components/Spin';
import cx from 'classnames';
import styles from './AnalizedCommentModal.module.less';
import { useIntercom } from 'react-use-intercom';
import ArrowTryOurText from 'assets/images/ArrowTryOurText.svg';
const { Panel } = Collapse;
type Props = {
  onClose: () => void;
  onTryAgain: () => void;
  isLoading: boolean;
  data: any;
  comment: string;
};

export default function AnalizedCommentModal(props: Props) {
  const isMobileScreen = useMediaMatch(MobileScreenQuery);
  const { onClose, onTryAgain, data, isLoading, comment } = props;
  const MAX_LENGTH = 80;
  const [showMore, setShowMore] = useState(false);
  const displayText = showMore
    ? data?.auto_response
    : data?.auto_response
    ? `${data.auto_response.slice(0, MAX_LENGTH)}...`
    : '';
  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const MAX_LENGTH_COMMENT = 160;
  const [showMoreComment, setShowMoreComment] = useState(false);
  const displayTextComment = showMoreComment
    ? comment
    : `${comment.slice(0, MAX_LENGTH_COMMENT)}${
        comment.length > MAX_LENGTH_COMMENT ? '...' : ''
      }`;
  const toggleShowMoreComment = () => {
    setShowMoreComment(!showMoreComment);
  };
  const getMood = (isPositive: boolean | null, text: string) => {
    if (isPositive === true) {
      return (
        <div className={cx(styles.listGrid, styles.success)}>
          <div className={styles.icon}>
            <Icon icon="smile" />
          </div>
          <h5 className={styles.title}>{text}</h5>
        </div>
      );
    } else if (isPositive === false) {
      return (
        <div className={cx(styles.listGrid, styles.error)}>
          <div className={styles.icon}>
            <Icon icon="frown" />
          </div>
          <h5 className={styles.title}>{text}</h5>
        </div>
      );
    } else {
      return (
        <div className={styles.listGrid}>
          <div className={styles.icon}>
            <Icon icon="meh" />
          </div>
          <h5 className={styles.title}>{text}</h5>
        </div>
      );
    }
  };
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);
  useEffect(() => {
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId);
      }
    };
  }, [timeoutId]);

  const { trackEvent, boot } = useIntercom();
  const handleTrackEventAndClose = () => {
    const id = setTimeout(() => {
      boot({ email: localStorage.getItem('email') || '' });
      trackEvent('return-from-results-page');
      setTimeoutId(id);
    }, 3 * 60 * 1000);
    onClose();
  };
  return (
    <Modal
      title={null}
      open
      centered
      closable={false}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
      keyboard={!isLoading}
      destroyOnClose
      width="975px"
    >
      <>
        <div className="modal-header">
          <h2 className={cx('h4', styles.modalTitle)}>
            Outcome analysis
            {isLoading && <Spin className={styles.headSpinner} />}
          </h2>
          {!isLoading && (
            <button className="modal-close" onClick={handleTrackEventAndClose}>
              <Icon icon="cross" />
            </button>
          )}
          <div className={styles.hintWrap}>
            <div>
              <p className={styles.results}>
                <Icon icon="comment" />
                analyzed comment
              </p>
            </div>
            <div>
              <div className={cx(styles.hint, styles.hintA)}>
                <img src={ArrowTryOurText} alt="arrow to textarea" />
                <span>text from the analyzed comment</span>
              </div>
            </div>
          </div>
          <p className={styles.showMore}>
            {displayTextComment} &nbsp;
            {comment.length > MAX_LENGTH_COMMENT && (
              <button
                className={styles.showBtn}
                onClick={toggleShowMoreComment}
              >
                {showMoreComment ? 'Show less' : 'Show more'}
              </button>
            )}
          </p>
        </div>

        <div className="modal-scroll-mobile">
          <div className="modal-scroll-desc">
            {data && data.categories?.length > 0 && (
              <>
                <div className={styles.hintWrap}>
                  <div>
                    <p className={styles.results}>
                      <Icon icon="graph" />
                      Results categorized by service
                    </p>
                  </div>
                  <div>
                    <div className={cx(styles.hint, styles.hintB)}>
                      <img src={ArrowTryOurText} alt="arrow to textarea" />
                      <span>
                        {`here are valuable service categories under which the comment is being analyzed${
                          isMobileScreen ? ', tap this row to see details' : ''
                        }`}
                      </span>
                    </div>
                  </div>
                </div>

                {isMobileScreen ? (
                  <div className={styles.collapse}>
                    {data.categories.map((item: any, index: any) => {
                      if (!item?.rationale) {
                        return null;
                      }
                      return (
                        <React.Fragment key={index}>
                          <Collapse
                            accordion
                            ghost={true}
                            expandIconPosition="end"
                            expandIcon={({ isActive }) => (
                              <div>
                                <Icon
                                  icon="angle-down"
                                  className={cx(
                                    styles.collapseIcon,
                                    isActive ? styles.rotate : null,
                                  )}
                                />
                              </div>
                            )}
                          >
                            <Panel
                              header={
                                <>
                                  <span>
                                    {getMood(item.is_positive, item.name)}
                                  </span>
                                </>
                              }
                              key={`Category${index}`}
                            >
                              <p>{item.rationale}</p>
                            </Panel>
                          </Collapse>
                        </React.Fragment>
                      );
                    })}
                  </div>
                ) : (
                  <ul className={styles.list}>
                    {data.categories.map((item: any, index: any) => {
                      if (!item?.rationale) {
                        return null;
                      }
                      return (
                        <li key={index}>
                          {getMood(item.is_positive, item.name)}
                          <div className={styles.listGrid}>
                            <div className={styles.text}>{item.rationale}</div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </>
            )}
            {data && (
              <>
                {data.alert_reason && data.is_positive_alert !== null && (
                  <>
                    <div className={styles.hintWrap}>
                      <div className={cx(styles.hint, styles.hintC)}>
                        <img src={ArrowTryOurText} alt="arrow to textarea" />
                        <span>
                          Trevise aims to direct your attention to any alerts or
                          compliments provided by the user
                        </span>
                      </div>
                    </div>
                    <div
                      className={cx(
                        styles.message,
                        data.is_positive_alert
                          ? styles.successMsg
                          : styles.errorMsg,
                      )}
                    >
                      <span className={styles.msgIcon}>
                        {!data.is_positive_alert ? (
                          <>
                            <Icon icon="warning" />
                          </>
                        ) : (
                          <>
                            <Icon icon="heart" />
                          </>
                        )}
                      </span>
                      <div className={styles.msgText}>
                        <h5 className={styles.msgTitle}>
                          {!data.is_positive_alert ? (
                            <>Service Alert</>
                          ) : (
                            <>Outstanding service</>
                          )}
                        </h5>
                        {data.alert_reason}
                      </div>
                    </div>
                  </>
                )}
                {data.suggestions && (
                  <>
                    <div className={styles.hintWrap}>
                      <div className={cx(styles.hint, styles.hintD)}>
                        <img src={ArrowTryOurText} alt="arrow to textarea" />
                        <span>
                          enhance your service with Trevise by extracting the
                          essentials from the comment analysis
                        </span>
                      </div>
                    </div>
                    <div className={styles.message}>
                      <span className={styles.msgIcon}>
                        <Icon icon="light" />
                      </span>
                      <div className={styles.msgText}>
                        <h5 className={styles.msgTitle}>Service Improvement</h5>
                        {data.suggestions}
                      </div>
                    </div>
                  </>
                )}
                {data.auto_response ? (
                  <>
                    <div className={styles.hintWrap}>
                      <div className={cx(styles.hint, styles.hintE)}>
                        <img src={ArrowTryOurText} alt="arrow to textarea" />
                        <span>
                          utilize the Quick Reply Suggestion feature for
                          improved customer service
                        </span>
                      </div>
                    </div>
                    <div className={styles.fontWrap}>
                      <h4 className={cx('h4', styles.responseTitle)}>
                        Quick reply suggestion
                      </h4>
                      {isMobileScreen ? (
                        <p>
                          {displayText} &nbsp;
                          <button
                            className={styles.showBtn}
                            onClick={toggleShowMore}
                          >
                            {showMore ? 'Show less' : 'Show more'}
                          </button>
                        </p>
                      ) : (
                        <p>{data.auto_response}</p>
                      )}
                      <CopyToClipboard text={data.auto_response}>
                        <div className={styles.copyWrap}>
                          <Button type="link" className="inverse-color">
                            Copy response &nbsp; <Icon icon="copy" />
                          </Button>
                        </div>
                      </CopyToClipboard>
                    </div>
                  </>
                ) : null}
              </>
            )}
          </div>

          {!isLoading ? (
            <div className={styles.footerBtn}>
              <Button
                onClick={onTryAgain}
                type="primary"
                htmlType="button"
                size="large"
                block
                className="inverse-color"
              >
                Try one more time
              </Button>
            </div>
          ) : (
            <div className={styles.mainSpinner}>
              <img alt="Working on this" src={treviseSpinner} />
            </div>
          )}
        </div>
      </>
    </Modal>
  );
}
