/* eslint-disable react/jsx-pascal-case */
import { Spin as $Spin, SpinProps as $SpinProps } from 'antd';

import spinner from 'assets/images/spin.svg';
import styles from './Spin.module.less';

export type SpinProps = $SpinProps;

export default function Spin(props: SpinProps) {
  const defaultIndicator = (
    <>
      <div className={styles.spinner}>
        <img src={spinner} alt="spinner" />
      </div>
    </>
  );

  const { indicator = defaultIndicator, ...rest } = props;

  return <$Spin data-testid="spin" indicator={indicator} {...rest} />;
}
