import React from 'react';
import { Modal, Button } from 'antd';
import Icon from 'components/Icon';

import cx from 'classnames';
import styles from './PrivacyPolicy.module.less';

type Props = {
  onClose: () => void;
};

export default function PrivacyPolicy(props: Props) {
  const { onClose } = props;

  return (
    <Modal
      title={null}
      open
      centered
      closable={false}
      maskClosable={false}
      onCancel={onClose}
      footer={null}
      destroyOnClose
      width="975px"
    >
      <>
        <div className="modal-header">
          <h2 className={cx('h4', styles.modalTitle)}>Privacy policy</h2>
          <button className="modal-close" onClick={onClose}>
            <Icon icon="cross" />
          </button>
        </div>

        <div className="modal-scroll-mobile">
          <div className="modal-scroll-desc">
            <div className={styles.wrap}>
              <time className={styles.date}>
                Last Updated: September 12, 2023
              </time>
              <p>
                This Privacy policy for GreenM (
                <strong>“Company”, “we”, “us”, or “our”</strong>), describes how
                and why we might collect, store, use, and/or share (
                <strong>“process”</strong>) your information when you use our
                service <strong>Trevise</strong>, such as when you:
              </p>
              <ul>
                <li>Visit the website at trevise.ai</li>
                <li>
                  Engage with us in other related ways, including any sales,
                  marketing, or events
                </li>
              </ul>
              <h2 className={cx('h4', styles.modalTitle)}>
                What information do we collect?
              </h2>
              <p>
                We collect personal information that you voluntarily provide to
                us when you express an interest in obtaining information about
                us or our product when you participate in activities on
                trevise.ai, or otherwise when you contact us.
              </p>
              <p>
                The personal information provided by you includes the business
                email address and company name. We also keep the feedback text
                and results of analysis for analytical purposes of Trevise
                development.
              </p>
              <h2 className={cx('h4', styles.modalTitle)}>
                What information do not we collect?
              </h2>
              <p>
                We do not collect such information due to the absence of its
                request/process on trevise.ai:
              </p>

              <ul>
                <li>Identifiers (e.g. first name, last name)</li>
                <li>
                  Commercial Information (e.g. records of software products
                  and/or services purchased, obtained, or considered)
                </li>
                <li>
                  Biometric Information (e.g. fingerprints and voiceprints)
                </li>
                <li>Geolocation Data (e.g. physical location, generally)</li>
                <li>
                  Education Information (e.g. student records and directory
                  information)
                </li>
                <li>Sensitive Personal Information</li>
              </ul>
              <h2 className={cx('h4', styles.modalTitle)}>
                Information automatically collected
              </h2>
              <p>
                Some information such as your Internet Protocol (IP) address
                and/or browser and device characteristics is collected
                automatically when you visit trevise.ai. We automatically
                collect certain information when you visit, use, or navigate
                trevise.ai. This information does not reveal your specific
                identity (like your name or contact information) but may include
                device and usage information, such as your IP address, browser
                and device characteristics, operating system, language
                preferences, referring URLs, device name, country, location,
                information about how and when you use our Services, and other
                technical information. This information is primarily needed to
                maintain the security and operation of trevise.ai and for our
                internal analytics and reporting purposes.
              </p>
              <p>
                Like many businesses, we also collect information through
                cookies and similar technologies.
              </p>
              <p>The information we collect includes:</p>
              <ul>
                <li>
                  Log and Usage Data. Log and usage data is service-related,
                  diagnostic, usage, and performance information our servers
                  automatically collect when you access or use our Services and
                  which we record in log files. Depending on how you interact
                  with us, this log data may include your IP address device
                  information, browser type, and settings and information about
                  your activity in the Services (such as the date/time stamps
                  associated with your usage, pages, and files viewed, searches,
                  and other actions you take such as which features you use),
                  device event information (such as system activity, error
                  reports (sometimes called "crash dumps"), and hardware
                  settings).
                </li>
                <li>
                  Device Data. We collect device data such as information about
                  your computer, phone, tablet, or other device you use to
                  access the Services. Depending on the device used, this device
                  data may include information such as your IP address (or proxy
                  server), device and application identification number,
                  location, browser type, hardware model, Internet service
                  provider and/or mobile carrier, operating system, and system
                  configuration information.
                </li>
              </ul>
              <h2 className={cx('h4', styles.modalTitle)}>
                How do we process your information?
              </h2>
              <p>
                We process your information to provide, improve, and administer
                trevise.ai, communicate with you, for security and fraud
                prevention, and to comply with law.
              </p>
              <p>
                We process your information for a variety of reasons depending
                on how you interact with trevise.ai, including:
              </p>
              <ul>
                <li>
                  To deliver and facilitate the delivery of services to the
                  user. We process your information to provide you with the
                  requested service.
                </li>
                <li>
                  To request feedback. We may process your information when
                  necessary to request feedback and to contact you about your
                  use of our Services.
                </li>
                <li>
                  To send you marketing and promotional communications. We may
                  process the personal information you send to us for our
                  marketing purposes if this is in accordance with your email
                  subscription preferences.
                </li>
                <li>
                  To identify usage trends. We process your information to
                  identify the industries which are the most interested in our
                  service, which we use for improving trevise.ai.
                </li>
                <li>
                  To determine the effectiveness of our marketing and
                  promotional campaigns. We process your information to better
                  understand how to provide marketing and promotional campaigns
                  that are most relevant to you.
                </li>
              </ul>
              <h2 className={cx('h4', styles.modalTitle)}>
                What legal bases do we rely on to process your information?
              </h2>
              <p>
                We only process your personal information when we believe it is
                necessary and we have a valid legal reason (i.e., legal basis)
                to do so under applicable law, like your consent, to comply with
                laws, to provide you with services to enter into or fulfill our
                obligation, to protect your rights, or to fulfill our legitimate
                business interests.
              </p>
              <h2 className={cx('h4', styles.modalTitle)}>
                When and with whom do we share your personal information?
              </h2>
              <p>
                We may share information in specific situations described in
                this section and/or with the following third parties.
              </p>
              <p>
                We may need to share your personal information in the following
                situation:
              </p>
              <ul>
                <li>
                  <strong>Business Transfers. </strong>We may share or transfer
                  your information in connection with or during negotiations of,
                  any merger, sale of company assets, financing, or acquisition
                  of all or a portion of our business to another company.
                </li>
              </ul>
              <h2 className={cx('h4', styles.modalTitle)}>
                How long do we keep your information?
              </h2>
              <p>
                We keep your information for as long as necessary to fulfill the
                purposes outlined in this privacy notice unless otherwise
                required by law.
              </p>
              <h2 className={cx('h4', styles.modalTitle)}>
                Do we make updates to this Privacy policy?
              </h2>
              <p>
                We may update this privacy policy from time to time. The updated
                version will be indicated by an “Last Updated” date and the
                updated version will be effective as soon as accessible. If we
                make changes to this privacy policy, we may notify you either by
                prominently posting a notice of such changes or by directly
                sending you a notification.
              </p>
              <h2 className={cx('h4', styles.modalTitle)}>
                How can you contact us about this Privacy policy?
              </h2>
              <p>
                If you have questions or comments about this Privacy policy, you
                may email us at{' '}
                <a
                  // target="_blank"
                  rel="noreferrer"
                  href={`mailto:hello@trevise.ai`}
                >
                  <Button className="inverse-color" type="link">
                    hello@trevise.ai
                  </Button>
                </a>{' '}
                or contact us by post at:
              </p>
              <p>GreenM</p>
              <div>
                USA office
                <address>Redwood City, CA, 541 Jefferson Ave, #100</address>
              </div>
              <br />
              <div>
                Ukrainian offices
                <address>Kyiv, 3 Dorohozhytska Str., #27</address>
                <address>Kharkiv, 10 Nezalezhnosti Ave, #716</address>
                <address>Lviv, 11 Shpytalna Str.</address>
              </div>
            </div>
          </div>
        </div>
      </>
    </Modal>
  );
}
