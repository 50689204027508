/* eslint-disable react/jsx-pascal-case */
import { Layout as $Layout } from 'antd';
import ConsentPopUp from 'components/ConsentPopUp';

import Router from 'Router';
import Footer from './Footer';
import Header from './Header';
const { Content } = $Layout;

export default function Layout() {
  return (
    <$Layout>
      <Header />
      <Content>
        <ConsentPopUp />
        <Router />
      </Content>
      <Footer />
    </$Layout>
  );
}
