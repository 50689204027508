import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import HomePage from 'screens/HomePage';

export default function Router() {
  return (
    <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
