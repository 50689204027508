import cx from 'classnames';

import defaultIconSet from '../../assets/icons/selection.json';
import IcomoonReact from 'icomoon-react';

import styles from './Icon.module.less';

type Props = {
  color?: string;
  size?: string | number;
  icon: string;
  iconSet?: Object;
  className?: string;
  id?: string;
};

const Icon: React.FC<Props> = (props) => {
  const {
    icon,
    iconSet = defaultIconSet,
    className = '',
    size = '1em',
    ...rest
  } = props;

  return (
    <IcomoonReact
      style={{ verticalAlign: '' }}
      className={cx(styles.root, className)}
      iconSet={iconSet}
      size={size}
      icon={icon}
      {...rest}
    />
  );
};

export default Icon;
