/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EmailDto = {
    email: string;
    company: string;
    subscriptionPlan?: EmailDto.subscriptionPlan;
};

export namespace EmailDto {

    export enum subscriptionPlan {
        TRIAL = 'trial',
        STARTER = 'starter',
        PRO = 'pro',
        ENTERPRISE = 'enterprise',
    }


}

