/* eslint-disable react/jsx-pascal-case */
import { useState } from 'react';
import { Layout } from 'antd';
// import cx from 'classnames';
import { Button } from 'antd';
import styles from './Header.module.less';
import logo from 'assets/images/logo.svg';
import JoinBetaModal from 'screens/JoinBetaModal';
export interface HeaderProps {
  onSignInClick: () => void;
  onLogoutClick: () => void;
}

const { Header: $Header } = Layout;

export default function Header() {
  const [isJoinBetaModalVisible, setIsJoinBetaModalVisible] = useState(false);
  return (
    <>
      {isJoinBetaModalVisible && (
        <JoinBetaModal
          onClose={() => setIsJoinBetaModalVisible(false)}
          header="Join Beta"
          text="Join the exclusive beta program and stay updated with the latest news and Trevise updates!"
          buttonName="Join beta"
        />
      )}

      <$Header className={styles.root}>
        <nav>
          <ul className={styles.menu}>
            <li className={styles.logo}>
              <img src={logo} alt="logo" />
            </li>
            <li>
              <Button
                type="link"
                size="middle"
                onClick={() => {
                  setIsJoinBetaModalVisible(true);
                }}
              >
                Join beta
              </Button>
            </li>
          </ul>
        </nav>
      </$Header>
    </>
  );
}
