import React, { useEffect, useState } from 'react';
import main from 'assets/images/main.png';
import { Button, Row, Col } from 'antd';
import Icon from 'components/Icon';
import styles from './AboutTreviseSection.module.less';
import cx from 'classnames';
import { useIntercom } from 'react-use-intercom';
export default function AboutTreviseSection() {
  const [showMoreLess, setShowMoreLess] = useState(false);
  const { boot } = useIntercom();
  useEffect(() => {
    boot();
  });
  return (
    <section className={styles.section}>
      <div className="container">
        <div className={styles.titleWrap}>
          <p className="h2">Ask Trevise</p>
          <h1 className="h1">
            Your AI-powered ally in customer sentiment analysis
          </h1>
          <p className={styles.subtitle}>
            Transforming opinions into opportunities
          </p>
        </div>
        <div className={styles.img}>
          <img src={main} alt="main" />
        </div>
        <Row gutter={30}>
          <Col md={7}>
            <h2 className="h2">About Trevise</h2>
          </Col>
          <Col md={15}>
            <div className={styles.textBox}>
              <p className={styles.text}>
                Want to provide the best customer service and are in search of
                the best sentiment analysis tools? Then you're on the right
                page. Trevise reads the vibes in reviews, comments, or messages
                and tells you if it’s good, bad, or meh. No sweat, just clear
                insights that can shape your customer service strategy. And
                don't be afraid of Trevise feedback, cause it also provides you
                with the ways to turn "meh" service into "wow". So, be
                up-to-date with advanced Generative AI, deciphering nuanced
                feedback and empowering impactful actions
              </p>
              {showMoreLess && (
                <p className={styles.subText}>
                  The team behind Trevise leverages 9 years of extensive
                  experience in data engineering, with a particular emphasis on
                  Natural Language Processing (NLP) tools and techniques.
                  Drawing from our expertise, we have created Trevise, a
                  platform designed to empower businesses with NLP sentiment
                  analysis by harnessing the potential of customer feedback and
                  transforming it into strategic advantages. With this sentiment
                  analysis tool, you can unlock valuable insights, make
                  data-driven decisions, and stay ahead of the competition in
                  today's dynamic market landscape.
                </p>
              )}
              <Button
                onClick={() => {
                  setShowMoreLess((prev) => !prev);
                }}
                type="link"
                size="middle"
              >
                Show {showMoreLess ? 'less' : 'more'}
                <Icon
                  className={cx({ [styles.open]: showMoreLess })}
                  icon="chevron-down"
                />
              </Button>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
}
