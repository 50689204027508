import { apiClient } from '.';
import { EmailDto } from 'services/api';
class CommonApi {
  public async saveEmail({ email, subscriptionPlan, company }: EmailDto) {
    return apiClient.default.appControllerSaveUserEmail({
      email,
      subscriptionPlan,
      company,
    });
  }
  public async getComments() {
    return apiClient.default.appControllerGetComments();
  }
}

export default new CommonApi();
