import React from 'react';
import { Carousel } from 'antd';
import styles from './KeyFeaturesSection.module.less';
import AllDriven from 'assets/images/sliderPhotos/AllDriven.png';
import Alerts from 'assets/images/sliderPhotos/Alerts.png';
import ActionableIntelligence from 'assets/images/sliderPhotos/ActionableIntelligence.png';
import MultilingualSupport from 'assets/images/sliderPhotos/MultilingualSupport.png';
import ScalablePricing from 'assets/images/sliderPhotos/ScalablePricing.png';
import AIGeneratedResponses from 'assets/images/sliderPhotos/AIGeneratedResponses.png';
import { MobileScreenQuery } from 'utils/mediaQuery.utils';
import { useMediaMatch } from 'rooks';
import cx from 'classnames';

export default function KeyFeaturesSection() {
  const isMobileScreen = useMediaMatch(MobileScreenQuery);
  const defaultSlides: any[] = [
    {
      id: '1',
      image: AllDriven,
      header: 'AI-driven insights',
      text: 'Harness the power of Generative AI for nuanced, context-aware sentiment analysis',
    },
    {
      id: '2',
      image: Alerts,
      header: 'Alerts',
      text: 'Stay ahead with announcements of critical sentiment shifts in your customer feedback',
    },
    {
      id: '3',
      image: ActionableIntelligence,
      header: 'Actionable intelligence',
      text: 'It’s not just about analyzing, it’s about transforming feedback into clear, actionable strategies for your business',
    },
    {
      id: '4',
      image: MultilingualSupport,
      header: 'Multilingual support',
      text: 'Deliver top-notch service to all your customers. With multilingual sentiment analysis, you can offer responses tailored for their needs',
    },
    {
      id: '5',
      image: ScalablePricing,
      header: 'Scalable pricing',
      text: 'Our transparent, pay-per-feedback model ensures you pay only for the insights you receive',
    },
    {
      id: '6',
      image: AIGeneratedResponses,
      header: 'AI response generator',
      text: 'Enjoy direct access to suggested answers that are automatically generated after a thorough analysis of your customers’ feedback',
    },
  ];
  return (
    <section className={styles.section}>
      <div className="container">
        <h2 className={cx('h2', styles.title)}>Key features</h2>

        {isMobileScreen ? (
          <>
            <div className={styles.CarouselContainer}>
              <Carousel dots centerMode infinite={false} slidesToShow={1}>
                {defaultSlides.map((item) => {
                  return (
                    <div className={styles.CarouselSlide} key={item.id}>
                      <div className={styles.card}>
                        <div className={styles.card}>
                          <img src={item.image} alt="" />
                        </div>
                        <h3 className={styles.cardTitle}>{item.header}</h3>
                        <p>{item.text}</p>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </>
        ) : (
          <>
            <div className={styles.grid}>
              {defaultSlides.map((item) => {
                return (
                  <div className={styles.card} key={item.id}>
                    <div className={styles.cardImg}>
                      <img src={item.image} alt="" />
                    </div>
                    <h3 className={styles.cardTitle}>{item.header}</h3>
                    <p>{item.text}</p>
                  </div>
                );
              })}
            </div>
          </>
        )}
      </div>
    </section>
  );
}
