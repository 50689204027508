/* eslint-disable react/jsx-pascal-case */
import { Layout } from 'antd';
import React, { useState } from 'react';
import logo from 'assets/images/logo.svg';
import logoGreenM from 'assets/images/logoGreenM.svg';

import styles from './Footer.module.less';
import PrivacyPolicy from 'screens/PrivacyPolicy';

const { Footer: $Footer } = Layout;

export default function Footer() {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  return (
    <>
      {showPrivacyPolicy && (
        <PrivacyPolicy
          onClose={() => {
            setShowPrivacyPolicy(false);
          }}
        />
      )}
      <$Footer className={styles.footer}>
        <nav>
          <ul className={styles.footerNav}>
            <li className={styles.logoBox}>
              <img src={logo} alt="trevise logo" />
              <span>by</span>
              <img src={logoGreenM} alt="greenm logo" />
            </li>

            <li>
              <span
                onClick={() => {
                  setShowPrivacyPolicy(true);
                }}
                className={styles.footerLink}
              >
                Privacy policy
              </span>
              <span>Copyright © Trevise 2023. All rights reserved</span>
            </li>
          </ul>
        </nav>
        <div className={styles.flag}>
          <div>Proudly Made in Ukraine</div>
          <div>#StandWithUkraine</div>
        </div>
      </$Footer>
    </>
  );
}
