import React, { useState } from 'react';
import { PopupModal, useCalendlyEventListener } from 'react-calendly';
import bannerImg from 'assets/images/pas.svg';
import ReactGA from 'react-ga4';

import { Button } from 'antd';
import JoinBetaModal from 'screens/JoinBetaModal';
import Icon from 'components/Icon';
import styles from './StartYourJourneySecrion.module.less';
import Pricing from 'screens/Pricing';
import { useIntercom } from 'react-use-intercom';

export default function StartYourJourneySecrion() {
  const [isJoinBetaModalVisible, setIsJoinBetaModalVisible] = useState(false);
  const [isBookMeetingModalVisible, setIsBookMeetingModalVisible] =
    useState(false);

  useCalendlyEventListener({
    onEventScheduled: (e) => {
      ReactGA.event({
        category: 'Button',
        action: 'calendly_event_booked',
        label: 'calendly_event_booked',
      });
    },
  });
  const [isPricingModalVisible, setIsPricingModalVisible] = useState(false);
  const { trackEvent, boot } = useIntercom();
  const handleTrackEvent = () => {
    boot({ email: localStorage.getItem('email') || '' });
    trackEvent('pricing-pop-up-view');
  };
  return (
    <>
      {
        <PopupModal
          open={isBookMeetingModalVisible}
          onModalClose={() => {
            setIsBookMeetingModalVisible(false);
          }}
          rootElement={document.getElementById('root') as HTMLElement}
          url="https://calendly.com/trevise/trevise-product-demo"
        />
      }
      {isJoinBetaModalVisible && (
        <JoinBetaModal
          onClose={() => setIsJoinBetaModalVisible(false)}
          header="Join beta"
          text="Join the exclusive beta program and stay updated with the latest news and Trevise updates!"
          buttonName="Join beta"
        />
      )}
      {isPricingModalVisible && (
        <Pricing
          onClose={() => {
            setIsPricingModalVisible(false);
          }}
        />
      )}
      <div className={styles.bannerWrap}>
        <div className={styles.banner}>
          <div className={styles.bannerImg}>
            <img src={bannerImg} alt="main" />
          </div>
          <div className={styles.bannerText}>
            <h2 className="h4">How much does Trevise cost?</h2>
            <p>
              Pay as you go! With Trevise scalable pricing system, you will pay
              only for the feedback analysis you actually use and save your
              money
            </p>
          </div>
          <div className={styles.bannerBtn}>
            <Button
              onClick={() => {
                handleTrackEvent();
                setIsPricingModalVisible(true);
              }}
              size="large"
              className="inverse-color"
              block
            >
              Learn the price
            </Button>
          </div>
        </div>
      </div>
      <section className={styles.section}>
        <div className="container">
          <h2 className="h3">
            Get ready to turn customer opinions into your next strategic move
          </h2>
          <h2 className={styles.title}>Start your Trevise journey today</h2>
          <p className={styles.subtitle}>
            <strong>Join Trevise now to be among the first</strong>
          </p>
          <div className={styles.btnBox}>
            <Button
              size="large"
              type="primary"
              block
              onClick={() => {
                setIsJoinBetaModalVisible(true);
              }}
            >
              Join beta
            </Button>
            <Button
              onClick={() => {
                ReactGA.event({
                  category: 'Button',
                  action: 'request_demo_click',
                  label: 'Request Demo Click',
                });
                setIsBookMeetingModalVisible(true);
              }}
              type="link"
            >
              Request demo
            </Button>
          </div>
          <p className={styles.caption}>
            Have questions? <br />
            We’re here to help. Reach out to us at
          </p>
          <a
            className={styles.mail}
            // target="_blank"
            rel="noreferrer"
            href={`mailto:hello@trevise.ai`}
          >
            <Button type="link">hello@trevise.ai</Button>
          </a>
          <p className={styles.caption}>Or connect with us on</p>
          <ul className={styles.socialList}>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.facebook.com/TreviseAI"
              >
                <Button size="small" type="link">
                  <Icon icon="facebook" /> Facebook
                </Button>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.instagram.com/trevise.ai/"
              >
                <Button size="small" type="link">
                  <Icon icon="instagram" /> Instagram
                </Button>
              </a>
            </li>
            <li>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.linkedin.com/company/trevise/about/"
              >
                <Button size="small" type="link">
                  <Icon icon="linkedin" /> LinkedIn
                </Button>
              </a>
            </li>
          </ul>
        </div>
      </section>
    </>
  );
}
